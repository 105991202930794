import { Box, Typography } from "@mui/material";
import { ReactNode } from "react";
import Paper from "./Paper";

type SectionProps = {
  value?: ReactNode;
  label?: string;
  rightAction?: React.ReactElement;
  style?: React.CSSProperties;
};
const Section: React.FC<SectionProps> = (props) => {
  const { value, label, rightAction, style } = props;
  return (
    <Paper style={style}>
      {label && (
        <div
          style={{
            position: "absolute",
            top: "-9px",
          }}
        >
          <Typography color="#a0a0a0" fontSize="0.75rem">
            {label}
          </Typography>
        </div>
      )}
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Typography color="#a0a0a0" alignSelf="center" noWrap component="div">
          {value}
        </Typography>
        {rightAction}
      </Box>
    </Paper>
  );
};

export default Section;
