import { StateVariant } from "@tagged-state/core";
import { createContext, createTaggedStateHook } from "@tagged-state/react";

export type State =
  | StateVariant<"uninit">
  | StateVariant<"pending">
  | StateVariant<
      "ready",
      {
        contractAddress: string;
        ticket: string;
        chainId: string;
      }
    >
  | StateVariant<"error", { error: Error }>;

const { Context, ComponentGuard } = createContext<State>({
  tag: "uninit",
  data: {},
});

export const GetPaymentInfoContext = Context;
export const GetPaymentInfoGuard = ComponentGuard;

export const usePaymentInfo = createTaggedStateHook(Context, "ready" as const);
