import { StateVariant } from "@tagged-state/core";
import { createContext, createTaggedStateHook } from "@tagged-state/react";
import { BigNumber } from "ethers";
import { ISendPayment } from "../contract-payment-ethers";

export type State =
  | StateVariant<"uninit">
  | StateVariant<"pending">
  | StateVariant<
      "ready",
      {
        price: BigNumber;
        contract: ISendPayment;
      }
    >
  | StateVariant<"error", { error: Error }>;

const { Context, ComponentGuard } = createContext<State>({
  tag: "uninit",
  data: {},
});

export const ContractPaymentContext = Context;
export const ContractPaymentGuard = ComponentGuard;

export const useContractPayment = createTaggedStateHook(
  Context,
  "ready" as const,
);
