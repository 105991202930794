import styled from "@emotion/styled";
import { Paper as MUIPaper } from "@mui/material";

const Paper = styled(MUIPaper)({
  paddingLeft: 16,
  paddingRight: 16,
  paddingTop: 10,
  paddingBottom: 10,
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  gap: 2,
  width: "100%",
  minHeight: "56px",
  position: "relative",
});

export default Paper;
