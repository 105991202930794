import React from "react";
import { BrowserRouter } from "react-router-dom";
import Root from "./Root";
import ErrorBoundary from "../components/ErrorBoundary";
import ErrorPage from "../pages/ErrorPage";

const Entrypoint: React.FC = () => (
  <BrowserRouter>
    <ErrorBoundary renderError={ErrorPage}>
      <Root />
    </ErrorBoundary>
  </BrowserRouter>
);

export default Entrypoint;
