import React, { PropsWithChildren, useEffect, useState } from "react";
import { UnknownVariantError } from "../errors";
import { GetConsentInfoContext, ConsentInfo, State } from "./state";

type Props = PropsWithChildren<{
  getConsentInfo: () => Promise<ConsentInfo>;
}>;

const GetConsentInfoGuardInit: React.FC<Props> = ({
  children,
  getConsentInfo,
}) => {
  const [fetchState, setFetchState] = useState<State>({
    tag: "uninit",
    data: {},
  });

  useEffect(() => {
    setFetchState({ tag: "pending", data: {} });
    getConsentInfo()
      .then((consentInfoResponse) => {
        if (consentInfoResponse.tag === "skip") {
          const { tag, ...data } = consentInfoResponse;
          setFetchState({ tag, data });
          return;
        }

        if (consentInfoResponse.tag === "full") {
          const { tag, ...data } = consentInfoResponse;
          setFetchState({ tag, data });
          return;
        }

        throw new UnknownVariantError(consentInfoResponse);
      })
      .catch((error) => {
        setFetchState({ tag: "error", data: { error } });
      });
  }, [getConsentInfo]);

  return (
    <GetConsentInfoContext.Provider value={fetchState}>
      {children}
    </GetConsentInfoContext.Provider>
  );
};

export default GetConsentInfoGuardInit;
