import { Route, Routes } from "react-router-dom";
import ConsentPage from "../pages/ConsentPage";
import LoadingPage from "../pages/LoadingPage";
import LoginPage from "../pages/LoginPage";

const Root = () => (
  <Routes>
    <Route path="consent" element={<ConsentPage />} />
    <Route path="login" element={<LoginPage />} />
    <Route path="*" element={<LoadingPage />} />
  </Routes>
);

export default Root;
