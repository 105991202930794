import GetConsentInfoInit from "../subsystem/api/getConsentInfo/GetConsentInfoInit";
import { useCallback, useEffect } from "react";
import ErrorPage from "./ErrorPage";
import LoadingPage from "./LoadingPage";
import {
  GetConsentInfoGuard,
  State,
} from "../subsystem/api/getConsentInfo/state";
import { ConfigGuard, useConfig } from "../subsystem/config/state";
import { getConsentInfo, acceptConsent, rejectConsent } from "../api/consent";
import useChallengeParam from "../subsystem/challenge/useChallengeParam";
import { StateProps } from "@tagged-state/core";
import Layout from "./Layout";
import {
  Box,
  Button,
  Container,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper,
  Typography,
} from "@mui/material";
import theme from "../theme";
import { Task } from "@mui/icons-material";
import OnlinePollingInit from "../subsystem/onlinePolling/PaymentPollingInit";
import { pollOnline } from "../api/online";

const ConsentInfoLoading = () => (
  <LoadingPage message="Waiting for the get consent info..." />
);

const ConfigLoading = () => (
  <LoadingPage message="Downloading configuration..." />
);

const mappingScopeToReadableText: Record<string, string> = {
  openid: "Humanode Identifier",
};

type ScopesListProps = { scopes: string[] };
const ScopesList: React.FC<ScopesListProps> = ({ scopes }) => (
  <Paper
    sx={{
      width: "100%",
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
    }}
  >
    <List component="nav">
      {scopes.map((scope) => (
        <ListItem key={scope}>
          <ListItemIcon>
            <Task sx={{ height: 20, width: 40 }} />
          </ListItemIcon>
          <ListItemText primary={mappingScopeToReadableText[scope] || scope} />
        </ListItem>
      ))}
    </List>
  </Paper>
);

const RequestConsent: React.FC<StateProps<State>["full"]> = (props) => {
  const { clientName, scopes } = props;

  const consentChallenge = useChallengeParam("consent_challenge");
  const { baseUrl } = useConfig();

  const boundAcceptConsent = useCallback(
    () =>
      acceptConsent(baseUrl, consentChallenge).then(({ redirectTo }) => {
        window.location.href = redirectTo;
      }),
    [consentChallenge, baseUrl],
  );

  const boundRejectConsent = useCallback(
    () =>
      rejectConsent(baseUrl, consentChallenge).then(({ redirectTo }) => {
        window.location.href = redirectTo;
      }),
    [consentChallenge, baseUrl],
  );

  return (
    <Layout logo>
      <Container
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          width: "auto",
          gap: theme.spacing(2),
        }}
      >
        <Typography variant="h4" align="center">
          {clientName} is requesting access to some data
        </Typography>
        <Typography variant="body2" align="center">
          When you click "Accept" the service will be able to receive the
          following data:
        </Typography>

        <ScopesList scopes={scopes} />

        <Box>
          <Button variant="contained" onClick={boundAcceptConsent}>
            Accept
          </Button>
        </Box>
        <Box>
          <Button variant="outlined" onClick={boundRejectConsent}>
            Reject
          </Button>
        </Box>
      </Container>
    </Layout>
  );
};

const SkipConsent: React.FC<StateProps<State>["skip"]> = ({ redirectTo }) => {
  useEffect(() => {
    window.location.href = redirectTo;
  }, [redirectTo]);

  return <LoadingPage message="Redirecting..." />;
};

const ConsentLogic: React.FC = (props) => {
  const consentChallenge = useChallengeParam("consent_challenge");
  const { baseUrl, onlinePollingUrl } = useConfig();

  const boundGetConsentInfo = useCallback(async () => {
    // `client` will be part of the state when the design is implemented
    const consentInfo = await getConsentInfo(baseUrl, consentChallenge);
    if (consentInfo.tag === "full") {
      return {
        tag: consentInfo.tag,
        clientName: consentInfo.client.clientName,
        scopes: consentInfo.scopes,
      };
    }

    return {
      tag: consentInfo.tag,
      redirectTo: consentInfo.redirectTo,
    };
  }, [consentChallenge, baseUrl]);

  const handlePoll = useCallback(
    async () =>
      onlinePollingUrl
        ? await pollOnline(onlinePollingUrl, consentChallenge)
        : Promise.resolve(null),
    [onlinePollingUrl, consentChallenge],
  );

  return (
    <OnlinePollingInit poll={handlePoll}>
      <GetConsentInfoInit getConsentInfo={boundGetConsentInfo}>
        <GetConsentInfoGuard
          error={ErrorPage}
          pending={ConsentInfoLoading}
          skip={SkipConsent}
          full={RequestConsent}
          uninit={ConsentInfoLoading}
        />
      </GetConsentInfoInit>
    </OnlinePollingInit>
  );
};

const ConsentPage: React.FC = () => (
  <ConfigGuard
    uninit={ConfigLoading}
    pending={ConfigLoading}
    ready={ConsentLogic}
    error={ErrorPage}
  />
);

export default ConsentPage;
