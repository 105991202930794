import React, { PropsWithChildren, useEffect, useState } from "react";
import { FaceTecSDKContext, State } from "./state";

type Props = PropsWithChildren<{
  faceTecInit: () => Promise<boolean>;
  faceTecUnload: () => Promise<void>;
}>;

const FaceTecSDKInit: React.FC<Props> = ({
  children,
  faceTecInit,
  faceTecUnload,
}) => {
  const [value, setValue] = useState<State>({ tag: "pending", data: {} });

  useEffect(() => {
    const load = faceTecInit()
      .then((success) => {
        setValue(
          success ? { tag: "ready", data: {} } : { tag: "failed", data: {} },
        );
      })
      .catch((error) => {
        setValue({ tag: "error", data: { error } });
      });
    return () => {
      load.then(() => faceTecUnload());
    };
  }, [faceTecInit, faceTecUnload]);

  return (
    <FaceTecSDKContext.Provider value={value}>
      {children}
    </FaceTecSDKContext.Provider>
  );
};

export default FaceTecSDKInit;
