import React, { ErrorInfo, PropsWithChildren } from "react";

export type ErrorProps = {
  error: Error;
};

type Props = PropsWithChildren<{
  onError?: (error: Error, errorInfo: ErrorInfo) => void;
  renderError: React.ComponentType<ErrorProps>;
}>;

type State = {
  error: Error | null;
};

class ErrorBoundary extends React.Component<Props> {
  state: State = { error: null };

  static getDerivedStateFromError = (error: Error) => ({ error });

  componentDidCatch = (error: Error, errorInfo: ErrorInfo) => {
    console.error(error);
    this.props.onError?.(error, errorInfo);
  };

  render() {
    const { error } = this.state;
    const { renderError: Component, children } = this.props;

    if (error) {
      return <Component error={error} />;
    }

    return children;
  }
}

export default ErrorBoundary;
