import {
  PropsWithChildren,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import faceTecInit from "../subsystem/facetec/logic/faceTecInit";
import faceTecUnload from "../subsystem/facetec/logic/faceTecUnload";
import FaceTecSDKInit from "../subsystem/facetec/sdk/FaceTecSDKInit";
import { FaceTecSDKGuard } from "../subsystem/facetec/sdk/state";
import ErrorPage from "./ErrorPage";
import LoadingPage from "./LoadingPage";
import FaceTecCapture, {
  CaptureState,
} from "../subsystem/facetec/FaceTecCapture";
import {
  GetLoginInfoGuard,
  LoginInfo,
  State as GetLoginInfoState,
  useLoginInfoFull,
} from "../subsystem/api/getLoginInfo/state";
import GetLoginInfoInit from "../subsystem/api/getLoginInfo/GetLoginInfoInit";
import { StateProps, StateVariant } from "@tagged-state/core";
import { ConfigGuard, useConfig } from "../subsystem/config/state";
import { acceptLogin, getLoginInfo, rejectLogin } from "../api/login";
import useChallengeParam from "../subsystem/challenge/useChallengeParam";
import Layout from "./Layout";
import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Container,
  FormHelperText,
  Paper,
  Popover,
  Stack,
  Typography,
} from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import theme from "../theme";
import { ScanResultBlobDataSchema, ServerError } from "../api/errors";
import {
  PaymentPollingGuard,
  State as PaymentPollingState,
} from "../subsystem/paymentPolling/state";
import PaymentPollingInit from "../subsystem/paymentPolling/PaymentPollingInit";
import {
  ContractPaymentInit,
  ContractPaymentGuard,
  useContractPayment,
} from "../lib/contractPayment";
import EthersProviderInit from "../subsystem/web3-onboard/EthersProviderInit";
import { useConnectWallet, useSetChain } from "@web3-onboard/react";
import Web3OnboardInit from "../subsystem/web3-onboard/Web3OnboardInit";
import { Web3OnboardGuard } from "../subsystem/web3-onboard/web3OnboardInitState";
import GetPaymentInfoInit from "../subsystem/api/getPaymentInfo/GetPaymentInfoInit";
import { getPaymentInfo, pollPayment } from "../api/payment";
import {
  GetPaymentInfoGuard,
  usePaymentInfo,
} from "../subsystem/api/getPaymentInfo/state";
import { BigNumber } from "ethers";
import LocalStorageInit from "../subsystem/localStorage/LocalStorageInit";
import { useLocalStorage } from "../subsystem/localStorage/state";
import {
  EthersProviderGuard,
  useEthersProvider,
} from "../subsystem/web3-onboard/EthersProviderState";
import HexStringView from "../components/HexStringView";
import FaceTecStartSessionInit from "../subsystem/api/faceTecStartSession/FaceTecStartSessionInit";
import {
  FaceTecStartSessionGuard,
  useFaceTecStartSessionInfo,
} from "../subsystem/api/faceTecStartSession/state";
import { facetecStartSession } from "../api/facetec";
import chainsMapper from "../subsystem/web3-onboard/chainsMapper";
import TimelineWithBlocks, {
  getTimelineBlocks,
  TimelineBlock,
} from "../components/TimelineWithBlocks";
import Section from "../components/Section";
import buildDelay from "../subsystem/buildDelay";
import OnlinePollingInit from "../subsystem/onlinePolling/PaymentPollingInit";
import { pollOnline } from "../api/online";
import { LivenessDataHandler } from "../subsystem/facetec/makeProcessor";

type HexString = `0x${string}`;

const StartSessionLoading = () => (
  <LoadingPage message="Getting biometric session info..." />
);

const FaceTecSDKLoading = () => (
  <LoadingPage message="Initializing the biometric engine..." />
);

const FaceTecSDKFailed = () => (
  <Layout logo>
    <Container
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        gap: theme.spacing(2),
      }}
    >
      <Typography variant="h5" align="center">
        Biometric engine initialization failed
      </Typography>
      <Box>
        <Typography variant="body1">
          Check if your network connection is working.
        </Typography>
        <Typography variant="body1">
          You can also load this page in another browser.
        </Typography>
      </Box>
      <Box>
        <Button variant="contained" onClick={() => window.location.reload()}>
          Try again
        </Button>
      </Box>
    </Container>
  </Layout>
);

const LoginInfoLoading = () => (
  <LoadingPage message="Waiting for the get login info..." />
);

const PaymentInfoLoading = () => (
  <LoadingPage message="Waiting for the payment info..." />
);

const SmartContractLoading = () => (
  <LoadingPage message="Loading the smart contract interface..." />
);

const PaymentPollInitialLoadingPage = () => (
  <LoadingPage message="Checking payment status..." />
);

type PaymentPollingErrorGenericProps = {
  error: Error;
  onClick: () => void;
};
const PaymentPollingErrorGeneric: React.FC<PaymentPollingErrorGenericProps> = (
  props,
) => (
  <ErrorPage error={props.error}>
    <Stack gap={2} justifyContent="center" alignItems="center">
      <Typography variant="h5">Couldn’t open a connection to server</Typography>
      <Typography variant="body1">
        Check if the network connection is working fine,
      </Typography>
      <Typography variant="body1">
        Make sure the link you are using is correct.
      </Typography>
      <Button variant="contained" onClick={props.onClick}>
        retry
      </Button>
    </Stack>
  </ErrorPage>
);

const PaymentPollInitialLoadingErrorPage: React.FC<
  StateProps<PaymentPollingState>["initialLoadingError"]
> = (props) => {
  const { error, retryInitialLoading } = props;

  return (
    <PaymentPollingErrorGeneric error={error} onClick={retryInitialLoading} />
  );
};

const PaymentPollingErrorPage: React.FC<
  StateProps<PaymentPollingState>["pollError"]
> = (props) => {
  const { error, resumePolling } = props;

  return <PaymentPollingErrorGeneric error={error} onClick={resumePolling} />;
};

const StateBlocksView: React.FC = () => {
  const { currentChallangeData } = useLocalStorage();
  const { web3Provider } = useEthersProvider();
  const contractReceipt = currentChallangeData?.contractReceipt;

  const [blocks, setBlocks] = useState<TimelineBlock[]>([]);

  useEffect(() => {
    if (!contractReceipt) return;

    let handleCancelDelay: null | (() => void) = null;

    let handleNewBlock = async () => {
      while (true) {
        const [delay, cancelDelay] = buildDelay(5000);
        handleCancelDelay = cancelDelay;
        setBlocks(await getTimelineBlocks(web3Provider, contractReceipt));
        await delay;
      }
    };
    handleNewBlock();

    return () => {
      handleCancelDelay?.();
    };
  }, [contractReceipt, web3Provider]);

  if (!blocks.length) {
    return null;
  }

  return (
    <Box sx={{ height: "50px", marginTop: "20px", width: "100%" }}>
      <TimelineWithBlocks blocks={blocks} />
    </Box>
  );
};

type PaymentContractPayedProps = {
  onClick: () => void;
};
const PaymentContractPayed: React.FC<PaymentContractPayedProps> = (props) => {
  const { currentChallangeData, updateItem } = useLocalStorage();
  const { web3Provider } = useEthersProvider();
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [txConfirmed, setTxConfirmed] = useState(false);

  useEffect(() => {
    const contractTransaction = currentChallangeData?.contractTransaction;
    if (!contractTransaction) return;

    const getReceipt = async () => {
      const contractReceipt = await web3Provider.waitForTransaction(
        contractTransaction.hash,
      );

      setTxConfirmed(true);
      updateItem({
        contractReceipt,
      });
    };

    if (currentChallangeData?.contractReceipt) {
      return setTxConfirmed(true);
    }

    getReceipt();
  }, [
    currentChallangeData?.contractReceipt,
    currentChallangeData?.contractTransaction,
    updateItem,
    web3Provider,
  ]);

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  return (
    <Layout logo>
      <Stack gap={3} justifyContent="center" alignItems="center">
        <Container sx={{ width: "min-content" }}>
          <CircularProgress />
        </Container>
        <Typography variant="h5" align="center">
          {txConfirmed
            ? "Finalizing transaction..."
            : "Confirming transaction..."}
        </Typography>
        <StateBlocksView />
        <Button variant="text" onClick={props.onClick}>
          Go back
          <Stack
            onMouseEnter={handlePopoverOpen}
            onMouseLeave={handlePopoverClose}
            alignItems="center"
            paddingLeft={1}
          >
            <InfoIcon />
            <Popover
              id="goBackInfo"
              open={Boolean(anchorEl)}
              sx={{
                pointerEvents: "none",
              }}
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
              onClose={handlePopoverClose}
              disableRestoreFocus
            >
              <Typography maxWidth={400} sx={{ padding: 2 }}>
                If you think something went wrong you can go back to the
                transaction submission step to retry the payment. You can check
                the blockchain to ensure you didn't pay for the specified ticket
                yet.
              </Typography>
            </Popover>
          </Stack>
        </Button>
      </Stack>
    </Layout>
  );
};

const Web3WalletLoading = () => (
  <LoadingPage message="Initializing web3 wallet..." />
);
const EthersProviderLoading = () => (
  <LoadingPage message="Connecting to the web3 wallet..." />
);

const ConfigLoading = () => (
  <LoadingPage message="Downloading configuration..." />
);

interface RpcError extends Error {
  reason: string;
  code: string;
  action: string;
}
interface TransactionError extends RpcError {
  transaction: {
    data: string;
    to: string;
    from: string;
    value: BigNumber;
    gasLimit: BigNumber;
  };
}

const SkipLoginFlow: React.FC<StateProps<GetLoginInfoState>["skip"]> = ({
  redirectTo,
}) => {
  useEffect(() => {
    window.location.href = redirectTo;
  }, [redirectTo]);

  return <LoadingPage message="Redirecting..." />;
};

type CaptureCompletionProps = {
  redirectTo: string;
};

const CaptureCompletion: React.FC<CaptureCompletionProps> = ({
  redirectTo,
}) => {
  useEffect(() => {
    window.location.href = redirectTo;
  }, [redirectTo]);

  return (
    <Layout logo>
      <Container
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          width: "auto",
          gap: theme.spacing(2),
        }}
      >
        <Typography variant="h5" align="center">
          Capture complete
        </Typography>
      </Container>
    </Layout>
  );
};

const FaceTecSDKInitializer: React.FC<PropsWithChildren> = (props) => {
  const { children } = props;
  const { facetecSdkParams } = useFaceTecStartSessionInfo();

  const facetecInitRoutine = useCallback(
    async () => await faceTecInit({ ...facetecSdkParams }),
    [facetecSdkParams],
  );

  const facetecUnloadRoutine = useCallback(
    async () => await faceTecUnload(),
    [],
  );

  const ready = useCallback(() => <>{children}</>, [children]);

  return (
    <FaceTecSDKInit
      faceTecInit={facetecInitRoutine}
      faceTecUnload={facetecUnloadRoutine}
    >
      <FaceTecSDKGuard
        uninit={FaceTecSDKLoading}
        pending={FaceTecSDKLoading}
        ready={ready}
        failed={FaceTecSDKFailed}
        error={ErrorPage}
      />
    </FaceTecSDKInit>
  );
};

const CaptureFlow: React.FC = () => {
  const { sessionToken } = useFaceTecStartSessionInfo();
  const loginChallenge = useChallengeParam("login_challenge");

  const { baseUrl } = useConfig();
  const [redirectTo, setRedirectTo] = useState<string | null>(null);
  const [captureState, setCaptureState] = useState<CaptureState>({
    tag: "capturing",
  });
  const handleAcceptLogin = useCallback<LivenessDataHandler>(
    (livenessData) => {
      return acceptLogin(baseUrl, loginChallenge, livenessData)
        .then(({ redirectTo, scanResultBlob }) => {
          setRedirectTo(redirectTo);
          return { blob: scanResultBlob };
        })
        .catch((error: Error) => {
          if (ServerError.is(error, ScanResultBlobDataSchema)) {
            return { blob: error.data.payload.scanResultBlob };
          }

          return undefined;
        });
    },
    [baseUrl, loginChallenge],
  );

  const handleRejectLogin = useCallback(
    () =>
      rejectLogin(baseUrl, loginChallenge).then(({ redirectTo }) => {
        setCaptureState({ tag: "done" });
        setRedirectTo(redirectTo);
      }),
    [baseUrl, loginChallenge],
  );

  const handleCaptureAgain = useCallback(() => {
    setCaptureState({ tag: "capturing" });
  }, []);

  if (captureState.tag === "capturing") {
    return (
      <Layout logo>
        <Container
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            width: "auto",
            gap: theme.spacing(2),
          }}
        >
          <FaceTecCapture
            handleLivenessData={handleAcceptLogin}
            sessionToken={sessionToken}
            updateCaptureState={setCaptureState}
          />
          <Typography variant="h5" align="center">
            Capturing biometric data...
          </Typography>
        </Container>
      </Layout>
    );
  }

  if (captureState.tag === "error") {
    return (
      <ErrorPage error={captureState.data.error}>
        <Container
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            width: "auto",
            gap: theme.spacing(2),
          }}
        >
          <Typography variant="h5" align="center">
            Capture failed
          </Typography>
          <Button variant="contained" onClick={handleCaptureAgain}>
            Capture again
          </Button>
          <Button variant="outlined" onClick={handleRejectLogin}>
            Abort login
          </Button>
        </Container>
      </ErrorPage>
    );
  }

  if (!redirectTo) {
    throw new Error("Unexpected empty redirect");
  }

  return <CaptureCompletion redirectTo={redirectTo} />;
};

const FaceTecStartSession: React.FC<PropsWithChildren> = (props) => {
  const { children } = props;
  const { baseUrl } = useConfig();
  const loginChallenge = useChallengeParam("login_challenge");

  const handleFaceTecStartSession = useCallback(() => {
    return facetecStartSession(baseUrl, loginChallenge);
  }, [baseUrl, loginChallenge]);

  const ready = useCallback(() => <>{children}</>, [children]);

  return (
    <FaceTecStartSessionInit faceTecStartSession={handleFaceTecStartSession}>
      <FaceTecStartSessionGuard
        uninit={StartSessionLoading}
        pending={StartSessionLoading}
        ready={ready}
        error={ErrorPage}
      />
    </FaceTecStartSessionInit>
  );
};

const CaptureFlowGuard: React.FC = () => (
  <FaceTecStartSession>
    <FaceTecSDKInitializer>
      <CaptureFlow />
    </FaceTecSDKInitializer>
  </FaceTecStartSession>
);

type PaymentContractApprovalState =
  | StateVariant<"readyToPay">
  | StateVariant<"paying">
  | StateVariant<"payed">
  | StateVariant<"error", { error: TransactionError }>;

const PaymentContractApproval: React.FC = () => {
  const { wallet } = useEthersProvider();
  const { currentChallangeData, updateItem } = useLocalStorage();
  const { contract, price } = useContractPayment();
  const loginInfo = useLoginInfoFull();
  const paymentInfo = usePaymentInfo();
  const [state, setState] = useState<PaymentContractApprovalState>({
    tag: "readyToPay",
    data: {},
  });

  useEffect(() => {
    if (!currentChallangeData?.contractTransaction) return;

    setState({
      tag: "payed",
      data: {},
    });
  }, [currentChallangeData?.contractTransaction]);

  const ticket = useMemo(() => `0x${paymentInfo.ticket}`, [paymentInfo.ticket]);

  const handleClickOnPay = useCallback(() => {
    setState({ tag: "paying", data: {} });
    return contract
      .pay(ticket, { value: price })
      .then(async (contractTransaction) => {
        setState({ tag: "payed", data: {} });
        updateItem({
          loginInfoFull: loginInfo,
          paymentInfo,
          contractTransaction,
        });
      })
      .catch((error: TransactionError) => {
        setState({ tag: "error", data: { error } });
      });
  }, [loginInfo, paymentInfo, contract, price, ticket, updateItem]);

  const handleClickOnAlreadyPaid = useCallback(() => {
    setState({ tag: "payed", data: {} });
  }, []);

  if (state.tag === "payed") {
    return (
      <PaymentContractPayed
        onClick={() => {
          setState({
            tag: "readyToPay",
            data: {},
          });
        }}
      />
    );
  }

  return (
    <Layout logo>
      <Stack width={300} justifyContent="center" gap={2}>
        <Section
          label="Your address"
          value={
            <HexStringView
              value={wallet?.accounts[0].address}
              color="white"
              ellipsisLeftOffsetChars={16}
              ellipsisRightOffsetChars={8}
            />
          }
        />
        <Section
          label="Ticket"
          value={
            <HexStringView
              value={ticket}
              className="gold-text"
              ellipsisLeftOffsetChars={16}
              ellipsisRightOffsetChars={8}
            />
          }
        />
        <Box>
          <Button
            fullWidth
            disabled={state.tag === "paying"}
            variant="contained"
            onClick={handleClickOnPay}
          >
            Pay
          </Button>
          {state.tag === "error" && (
            <FormHelperText error>{state.data.error.reason}</FormHelperText>
          )}
        </Box>
        <Button fullWidth variant="text" onClick={handleClickOnAlreadyPaid}>
          I paid already
        </Button>
      </Stack>
    </Layout>
  );
};

const WrongNetwork: React.FC = () => {
  const { chainId } = usePaymentInfo();
  const { infuraId } = useConfig();
  const [{ settingChain }, setChain] = useSetChain();
  const chain = chainsMapper(chainId, infuraId);

  const switchNetwork = () => {
    setChain({
      chainId: chainId,
      chainNamespace: "evm",
    });
  };

  return (
    <Layout logo>
      <Paper>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            width: 450,
          }}
        >
          <Alert severity="error" variant="outlined">
            <Stack gap="10px">
              <Typography color="white" sx={{ fontWeight: "bold" }}>
                Change your wallet network
              </Typography>
              <Typography
                color="white"
                variant="body2"
              >{`You are trying to interact with the smart contract deployed to ${chain.label} network. Make sure that your wallet is set to the same network.`}</Typography>
              <Box>
                <Button
                  disabled={settingChain}
                  onClick={switchNetwork}
                  variant="contained"
                >
                  {`Switch to ${chain.label}`}
                </Button>
              </Box>
            </Stack>
          </Alert>
        </Box>
      </Paper>
    </Layout>
  );
};

const PaymentContractFlowReady: React.FC = () => {
  const { contractAddress, chainId } = usePaymentInfo();
  const { wallet, web3Provider } = useEthersProvider();
  const { mode } = useConfig();
  const [{ connectedChain }] = useSetChain();

  const singer = useMemo(
    () => web3Provider.getSigner(wallet.accounts[0].address),
    [wallet.accounts, web3Provider],
  );

  if (mode !== "development" && connectedChain?.id !== chainId) {
    return <WrongNetwork />;
  }

  return (
    <ContractPaymentInit
      contractAddress={contractAddress as HexString}
      singer={singer}
    >
      <ContractPaymentGuard
        uninit={SmartContractLoading}
        pending={SmartContractLoading}
        ready={PaymentContractApproval}
        error={ErrorPage}
      />
    </ContractPaymentInit>
  );
};

const EthersProviderUninit: React.FC = () => {
  const [{ wallet, connecting }, connect, disconnect] = useConnectWallet();
  const { contractAddress } = usePaymentInfo();

  const handleClick = useCallback(
    () => (wallet ? disconnect(wallet) : connect()),
    [connect, disconnect, wallet],
  );

  return (
    <Layout logo>
      <Stack maxWidth={400} justifyContent="center" gap={2}>
        <Typography component="div" color="gray" align="center">
          You are going to pay for bio verification via smart contract with
          address{" "}
          <HexStringView
            value={contractAddress}
            color="white"
            buttonSx={{
              verticalAlign: "baseline",
              textTransform: "inherit",
            }}
          />
        </Typography>
        <Typography align="center">Choose payment method:</Typography>
        <Button disabled={connecting} variant="contained" onClick={handleClick}>
          Connect your wallet
        </Button>
      </Stack>
    </Layout>
  );
};

const PaymentContractFlow: React.FC = () => {
  const connectWallet = useConnectWallet();

  return (
    <EthersProviderInit connectWallet={connectWallet}>
      <EthersProviderGuard
        uninit={EthersProviderUninit}
        pending={EthersProviderLoading}
        ready={PaymentContractFlowReady}
        error={ErrorPage}
      />
    </EthersProviderInit>
  );
};

const Web3OnboardSetup: React.FC<PropsWithChildren> = (props) => {
  const { children } = props;
  const { appMetadata, infuraId } = useConfig();
  const { chainId } = usePaymentInfo();

  const ready = useCallback(() => <>{children}</>, [children]);

  return (
    <Web3OnboardInit
      chainId={chainId}
      appMetadata={appMetadata}
      infuraId={infuraId}
    >
      <Web3OnboardGuard
        uninit={Web3WalletLoading}
        pending={Web3WalletLoading}
        ready={ready}
        error={ErrorPage}
      />
    </Web3OnboardInit>
  );
};

const PaymentPollingUnpayed = () => (
  <Web3OnboardSetup>
    <PaymentContractFlow />
  </Web3OnboardSetup>
);

const PaymentLogic: React.FC = () => {
  const { baseUrl, pollInterval } = useConfig();
  const loginChallenge = useChallengeParam("login_challenge");

  const handlePoll = useCallback(async () => {
    const res = await pollPayment(baseUrl, loginChallenge);
    return res.payed;
  }, [baseUrl, loginChallenge]);

  return (
    <PaymentPollingInit pollInterval={pollInterval} poll={handlePoll}>
      <PaymentPollingGuard
        uninit={PaymentInfoLoading}
        initialLoading={PaymentPollInitialLoadingPage}
        initialLoadingError={PaymentPollInitialLoadingErrorPage}
        pollError={PaymentPollingErrorPage}
        unpayed={PaymentPollingUnpayed}
        payed={CaptureFlowGuard}
      />
    </PaymentPollingInit>
  );
};

const GetPaymentInfo: React.FC = () => {
  const { baseUrl } = useConfig();
  const loginChallenge = useChallengeParam("login_challenge");
  const { currentChallangeData } = useLocalStorage();

  const boundGetPaymentInfo = useCallback(async () => {
    if (currentChallangeData?.paymentInfo) {
      return currentChallangeData.paymentInfo;
    }
    return getPaymentInfo(baseUrl, loginChallenge);
  }, [baseUrl, currentChallangeData, loginChallenge]);

  return (
    <GetPaymentInfoInit getPaymentInfo={boundGetPaymentInfo}>
      <GetPaymentInfoGuard
        uninit={PaymentInfoLoading}
        pending={PaymentInfoLoading}
        ready={PaymentLogic}
        error={ErrorPage}
      />
    </GetPaymentInfoInit>
  );
};

const FullLoginFlow: React.FC<StateProps<GetLoginInfoState>["full"]> = (
  props,
) => {
  const { paymentRequired } = props;

  if (!paymentRequired) {
    return <CaptureFlowGuard />;
  }

  return <GetPaymentInfo />;
};

const LoginLogic: React.FC = () => {
  const { baseUrl, onlinePollingUrl } = useConfig();
  const loginChallenge = useChallengeParam("login_challenge");
  const { currentChallangeData } = useLocalStorage();

  const boundGetLoginInfo = useCallback(async (): Promise<LoginInfo> => {
    if (currentChallangeData?.loginInfoFull) {
      return {
        tag: "full",
        ...currentChallangeData.loginInfoFull,
      };
    }
    return getLoginInfo(baseUrl, loginChallenge);
  }, [baseUrl, currentChallangeData, loginChallenge]);

  const handlePoll = useCallback(
    async () =>
      onlinePollingUrl
        ? await pollOnline(onlinePollingUrl, loginChallenge)
        : Promise.resolve(null),
    [onlinePollingUrl, loginChallenge],
  );

  return (
    <OnlinePollingInit poll={handlePoll}>
      <GetLoginInfoInit getLoginInfo={boundGetLoginInfo}>
        <GetLoginInfoGuard
          uninit={LoginInfoLoading}
          pending={LoginInfoLoading}
          full={FullLoginFlow}
          skip={SkipLoginFlow}
          error={ErrorPage}
        />
      </GetLoginInfoInit>
    </OnlinePollingInit>
  );
};

const LoginPage: React.FC = () => {
  const loginChallenge = useChallengeParam("login_challenge");

  return (
    <LocalStorageInit loginChallenge={loginChallenge}>
      <ConfigGuard
        uninit={ConfigLoading}
        pending={ConfigLoading}
        ready={LoginLogic}
        error={ErrorPage}
      />
    </LocalStorageInit>
  );
};

export default LoginPage;
