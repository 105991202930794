import { loadProduction } from "hybridconfig";
import { z } from "zod";

const MODE = ["development", "production"] as const;

const AppMetadataSchema = z.object({
  name: z.string(),
  icon: z.string(),
  logo: z.optional(z.string()),
  description: z.optional(z.string()),
  gettingStartedGuide: z.optional(z.string()),
  explore: z.optional(z.string()),
  recommendedInjectedWallets: z.array(
    z.object({
      name: z.string(),
      url: z.string(),
    }),
  ),
  agreement: z.optional(
    z.nullable(
      z.object({
        version: z.string(),
        termsUrl: z.optional(z.string()),
        privacyUrl: z.optional(z.string()),
      }),
    ),
  ),
});

const JsonStringSchema = <T extends z.ZodTypeAny>(schema: T) =>
  z.string().transform<z.infer<T>>((data) => schema.parse(JSON.parse(data)));

const makeConfigSchema = <
  T extends Record<string, z.ZodType<any, any, string>>,
>(
  val: T,
) => z.object(val);

const ConfigSchema = makeConfigSchema({
  baseUrl: z.string(),
  onlinePollingUrl: z.string(),
  pollInterval: JsonStringSchema(z.number()),
  mode: z.enum(MODE),
  appMetadata: JsonStringSchema(AppMetadataSchema),
  infuraId: z.string(),
});

export type Config = z.infer<typeof ConfigSchema>;

const config = loadProduction({
  remoteConfigPath: "/config.json",
  envKeyPrefix: "REACT_APP_",
}).then(ConfigSchema.parse);

export default config;
