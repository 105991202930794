import React, { PropsWithChildren, useEffect, useState } from "react";
import { UnknownVariantError } from "../errors";
import { GetLoginInfoContext, LoginInfo, State } from "./state";

type Props = PropsWithChildren<{
  getLoginInfo: () => Promise<LoginInfo>;
}>;

const GetLoginInfoGuardInit: React.FC<Props> = ({ children, getLoginInfo }) => {
  const [value, setValue] = useState<State>({ tag: "pending", data: {} });

  useEffect(() => {
    getLoginInfo()
      .then((loginInfoResponse) => {
        if (loginInfoResponse.tag === "skip") {
          const { tag, ...data } = loginInfoResponse;
          setValue({ tag, data });
          return;
        }

        if (loginInfoResponse.tag === "full") {
          const { tag, ...data } = loginInfoResponse;
          setValue({ tag, data });
          return;
        }

        throw new UnknownVariantError(loginInfoResponse);
      })
      .catch((error) => {
        setValue({ tag: "error", data: { error } });
      });
  }, [getLoginInfo]);

  return (
    <GetLoginInfoContext.Provider value={value}>
      {children}
    </GetLoginInfoContext.Provider>
  );
};

export default GetLoginInfoGuardInit;
