import { StateVariant } from "@tagged-state/core";
import { createContext, createTaggedStateHook } from "@tagged-state/react";

export type State =
  | StateVariant<"uninit">
  | StateVariant<"initialLoading">
  | StateVariant<
      "initialLoadingError",
      { error: Error; retryInitialLoading: () => void }
    >
  | StateVariant<"unpayed", {}>
  | StateVariant<
      "pollError",
      {
        error: Error;
        resumePolling: () => void;
      }
    >
  | StateVariant<"payed", {}>;

const { Context, ComponentGuard } = createContext<State>({
  tag: "uninit",
  data: {},
});

export const PaymentPollingContext = Context;
export const PaymentPollingGuard = ComponentGuard;

export const usePaymentPollingUnpayed = createTaggedStateHook(
  Context,
  "unpayed" as const,
);
