import { StateProps } from "@tagged-state/core";
import React, { PropsWithChildren, useEffect, useState } from "react";
import { FaceTecStartSessionContext, State } from "./state";

type Props = PropsWithChildren<{
  faceTecStartSession: () => Promise<StateProps<State>["ready"]>;
}>;

const FaceTecStartSessionInit: React.FC<Props> = ({
  children,
  faceTecStartSession,
}) => {
  const [value, setValue] = useState<State>({ tag: "pending", data: {} });

  useEffect(() => {
    faceTecStartSession()
      .then((data) => setValue({ tag: "ready", data }))
      .catch((error) => setValue({ tag: "error", data: { error } }));
  }, [faceTecStartSession]);

  return (
    <FaceTecStartSessionContext.Provider value={value}>
      {children}
    </FaceTecStartSessionContext.Provider>
  );
};

export default FaceTecStartSessionInit;
