import { StateVariant } from "@tagged-state/core";
import { createContext, createTaggedStateHook } from "@tagged-state/react";
import { WalletState } from "@web3-onboard/core";
import type { Web3Provider } from "@ethersproject/providers";

export type State =
  | StateVariant<"uninit">
  | StateVariant<"pending">
  | StateVariant<
      "ready",
      {
        wallet: WalletState;
        web3Provider: Web3Provider;
      }
    >
  | StateVariant<"error", { error: Error }>;

const { Context, ComponentGuard } = createContext<State>({
  tag: "uninit",
  data: {},
});

export const EthersProviderContext = Context;
export const EthersProviderGuard = ComponentGuard;

export const useEthersProvider = createTaggedStateHook(
  Context,
  "ready" as const,
);
