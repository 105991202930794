import { StateProps } from "@tagged-state/core";
import { ContractReceipt, ContractTransaction } from "ethers";
import { State as LoginInfoState } from "./api/getLoginInfo/state";
import { State as PaymentInfoState } from "./api/getPaymentInfo/state";

type Item = "login_challange";

export type LoginChallangeData = {
  paymentInfo: StateProps<PaymentInfoState>["ready"];
  loginInfoFull: StateProps<LoginInfoState>["full"];
  alreadyPaid: boolean;
  contractTransaction: ContractTransaction;
  contractReceipt: ContractReceipt;
};
type LoginChallangeDatas = Record<string, Partial<LoginChallangeData>>;

type LocalStorageData<I extends Item> = I extends "login_challange"
  ? LoginChallangeDatas
  : never;

const getItem = <I extends Item>(
  item: I,
  loginChallange: string,
): Partial<LoginChallangeData> | null => {
  const data = localStorage.getItem(item);
  const loginChallangeData = data
    ? (JSON.parse(data) as LocalStorageData<I>)
    : null;

  if (loginChallangeData === null) {
    return null;
  }

  return loginChallangeData[loginChallange];
};

const setItem = <I extends Item>(
  item: I,
  loginChallange: string,
  value: Partial<LoginChallangeData>,
): Partial<LoginChallangeData> => {
  const data = localStorage.getItem(item);
  const loginChallangeDatas = data
    ? (JSON.parse(data) as LoginChallangeDatas)
    : {};

  const currentChallangeData = {
    ...loginChallangeDatas[loginChallange],
    ...value,
  };

  loginChallangeDatas[loginChallange] = currentChallangeData;

  localStorage.setItem(item, JSON.stringify(loginChallangeDatas));

  return currentChallangeData;
};

const typedLocalStorage = {
  getItem,
  setItem,
};

export default typedLocalStorage;
