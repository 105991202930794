import { PropsWithChildren, useCallback, useEffect, useState } from "react";
import typedLocalStorage, { LoginChallangeData } from "../typedLocalStorage";
import { LocalStorageContext, State } from "./state";

type LocalStorageInitProps = {
  loginChallenge: string;
};
const LocalStorageInit: React.FC<PropsWithChildren<LocalStorageInitProps>> = (
  props,
) => {
  const { children, loginChallenge } = props;
  const [value, setValue] = useState<State>({
    tag: "uninit",
    data: {},
  });

  const updateItem = useCallback(
    (value: Partial<LoginChallangeData>) => {
      const currentChallangeData = typedLocalStorage.setItem(
        "login_challange",
        loginChallenge,
        value,
      );

      setValue((prevValue) => {
        if (prevValue.tag === "ready") {
          prevValue.data.currentChallangeData = currentChallangeData;
        }
        return prevValue;
      });
    },
    [loginChallenge],
  );

  useEffect(() => {
    const currentChallangeData = typedLocalStorage.getItem(
      "login_challange",
      loginChallenge,
    );

    setValue({
      tag: "ready",
      data: {
        currentChallangeData,
        updateItem,
      },
    });
  }, [loginChallenge, updateItem]);

  return (
    <LocalStorageContext.Provider value={value}>
      {children}
    </LocalStorageContext.Provider>
  );
};

export default LocalStorageInit;
