import { apiCall } from "./apiCall";
import { z } from "zod";

const PATH = {
  getPaymentInfo: "/payment/info",
  pollPayment: "/payment/poll",
};

const PaymentInfoSchema = z.object({
  contractAddress: z.string(),
  ticket: z.string(),
  chainId: z.string(),
});

export const getPaymentInfo = (url: string, loginChallenge: string) =>
  apiCall({
    url: url + PATH.getPaymentInfo,
    authorizationToken: loginChallenge,
    responseSchema: PaymentInfoSchema,
  });

const PaymentPollSchema = z.object({
  payed: z.boolean(),
});

export const pollPayment = (url: string, loginChallenge: string) =>
  apiCall({
    url: url + PATH.pollPayment,
    authorizationToken: loginChallenge,
    responseSchema: PaymentPollSchema,
  });
