import { apiCall } from "./apiCall";
import { z } from "zod";

const PATH = {
  facetecStartSession: "/facetec/start-session",
};

const FacetecSdkParamsSchema = z.object({
  publicFaceMapEncryptionKey: z.string(),
  deviceKeyIdentifier: z.string(),
  productionKey: z.string(),
});

const FaceTecStartSessionSchema = z.object({
  sessionToken: z.string(),
  facetecSdkParams: FacetecSdkParamsSchema,
});

export const facetecStartSession = (url: string, loginChallenge: string) =>
  apiCall({
    url: url + PATH.facetecStartSession,
    authorizationToken: loginChallenge,
    method: "POST",
    responseSchema: FaceTecStartSessionSchema,
  });
