import { DelegatedFlatStateVariant, StateVariant } from "@tagged-state/core";
import { createContext } from "@tagged-state/react";

export type ConsentInfo =
  | { tag: "skip"; redirectTo: string }
  | { tag: "full"; clientName: string; scopes: string[] };

export type State =
  | StateVariant<"uninit">
  | StateVariant<"pending">
  | DelegatedFlatStateVariant<"skip", ConsentInfo>
  | DelegatedFlatStateVariant<"full", ConsentInfo>
  | StateVariant<"error", { error: Error }>;

const { Context, ComponentGuard } = createContext<State>({
  tag: "uninit",
  data: {},
});

export const GetConsentInfoContext = Context;
export const GetConsentInfoGuard = ComponentGuard;
