import { StateProps } from "@tagged-state/core";
import React, { PropsWithChildren, useEffect, useState } from "react";
import { GetPaymentInfoContext, State } from "./state";

type Props = PropsWithChildren<{
  getPaymentInfo: () => Promise<StateProps<State>["ready"]>;
}>;

const GetPaymentInfoGuardInit: React.FC<Props> = ({
  children,
  getPaymentInfo,
}) => {
  const [value, setValue] = useState<State>({ tag: "pending", data: {} });

  useEffect(() => {
    getPaymentInfo()
      .then((data) =>
        setValue({
          tag: "ready",
          data,
        }),
      )
      .catch((error) => setValue({ tag: "error", data: { error } }));
  }, [getPaymentInfo]);

  return (
    <GetPaymentInfoContext.Provider value={value}>
      {children}
    </GetPaymentInfoContext.Provider>
  );
};

export default GetPaymentInfoGuardInit;
