import { StateVariant } from "@tagged-state/core";
import { createContext, createTaggedStateHook } from "@tagged-state/react";
import { LoginChallangeData } from "../typedLocalStorage";

export type State =
  | StateVariant<"uninit">
  | StateVariant<
      "ready",
      {
        updateItem: (value: Partial<LoginChallangeData>) => void;
        currentChallangeData: Partial<LoginChallangeData> | null;
      }
    >
  | StateVariant<"error", { error: Error }>;

const { Context, ComponentGuard } = createContext<State>({
  tag: "uninit",
  data: {},
});

export const LocalStorageContext = Context;
export const LocalStorageGuard = ComponentGuard;

export const useLocalStorage = createTaggedStateHook(Context, "ready" as const);
