import React from "react";
import { CssBaseline } from "@mui/material";
import { StyledEngineProvider, ThemeProvider } from "@mui/material/styles";
import Entrypoint from "./routes/Entrypoint";
import ConfigInit from "./subsystem/config/ConfigInit";
import theme from "./theme";
import "./styles.css";

const App: React.FC = () => (
  <StyledEngineProvider injectFirst>
    <ThemeProvider theme={theme}>
      <CssBaseline>
        <ConfigInit>
          <Entrypoint />
        </ConfigInit>
      </CssBaseline>
    </ThemeProvider>
  </StyledEngineProvider>
);

export default App;
