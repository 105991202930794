import React, { PropsWithChildren, useEffect, useState } from "react";
import { State, EthersProviderContext } from "./EthersProviderState";
import { useConnectWallet } from "@web3-onboard/react";
import { ethers } from "ethers";

type EthersProviderInitProps = {
  connectWallet: ReturnType<typeof useConnectWallet>;
};
const EthersProviderInit: React.FC<
  PropsWithChildren<EthersProviderInitProps>
> = (props) => {
  const { connectWallet, children } = props;
  const [{ wallet, connecting }] = connectWallet;
  const [value, setValue] = useState<State>({
    tag: "pending",
    data: {},
  });

  useEffect(() => {
    if (wallet && !connecting) {
      const web3Provider = new ethers.providers.Web3Provider(wallet.provider);

      return setValue({
        tag: "ready",
        data: {
          wallet,
          web3Provider,
        },
      });
    }

    setValue({
      tag: connecting ? "pending" : "uninit",
      data: {},
    });
  }, [connecting, wallet]);

  return <EthersProviderContext.Provider value={value} children={children} />;
};

export default EthersProviderInit;
