import { StateVariant } from "@tagged-state/core";
import { createContext } from "@tagged-state/react";

export type State =
  | StateVariant<"uninit">
  | StateVariant<"polling", { online: number }>;

const { Context, ComponentGuard } = createContext<State>({
  tag: "uninit",
  data: {},
});

export const OnlinePollingContext = Context;
export const OnlinePollingGuard = ComponentGuard;
