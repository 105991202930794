import { CircularProgress, Container, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { useTimeout } from "react-use";
import { OnlinePollingGuard } from "../subsystem/onlinePolling/state";
import theme from "../theme";
import Layout from "./Layout";

export type LoadingPageProps = {
  message?: string;
};

const UninitPollStatus: React.FC = () => null;

const PollStatus: React.FC<{ online: number }> = (props) => {
  const [isReady] = useTimeout(30000);

  if (props.online === 1 || !isReady()) return null;

  return (
    <Typography variant="h6" align="center">
      {props.online} users are waiting. You might be next.
    </Typography>
  );
};

const LoadingPage: React.FC<LoadingPageProps> = ({ message }) => (
  <Layout logo>
    <Container
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        width: "auto",
        gap: theme.spacing(2),
      }}
    >
      <Container sx={{ width: "min-content" }}>
        <CircularProgress />
      </Container>
      <Box sx={{ minHeight: 32 }}>
        <OnlinePollingGuard uninit={UninitPollStatus} polling={PollStatus} />
      </Box>
      <Typography variant="h5" align="center">
        {message}
      </Typography>
    </Container>
  </Layout>
);

export default LoadingPage;
