import { PropsWithChildren, useEffect, useState } from "react";
import buildDelay from "../buildDelay";
import { OnlinePollingContext, State } from "./state";

type OnlinePollingInitProps = {
  poll: () => Promise<{ pollInterval: number; online: number } | null>;
};

const OnlinePollingInit: React.FC<PropsWithChildren<OnlinePollingInitProps>> = (
  props,
) => {
  const { children, poll } = props;
  const [value, setValue] = useState<State>({ tag: "uninit", data: {} });

  useEffect(() => {
    let handleCancelDelay: null | (() => void) = null;

    const run = async () => {
      let pollInterval = 30;
      while (true) {
        const [delay, cancelDelay] = buildDelay(pollInterval * 1000);
        handleCancelDelay = cancelDelay;
        try {
          const res = await poll();
          if (res === null) {
            break;
          }
          pollInterval = res.pollInterval;

          setValue({
            tag: "polling",
            data: {
              online: res.online,
            },
          });
        } catch (error) {}

        await delay;
      }
    };

    run();

    return () => {
      handleCancelDelay?.();
    };
  }, [poll]);

  return <OnlinePollingContext.Provider value={value} children={children} />;
};

export default OnlinePollingInit;
