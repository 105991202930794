import { z } from "zod";
import { apiCall } from "./apiCall";

const PATH = {
  getConsentInfo: "/consent",
  acceptConsent: "/consent/accept",
  rejectConsent: "/consent/reject",
};

const ClientInfoSchema = z.object({
  clientId: z.string(),
  clientName: z.string(),
  owner: z.string(),
  policyUri: z.string(),
  tosUri: z.string(),
  clientUri: z.string(),
  logoUri: z.string(),
  contacts: z.array(z.string()),
});

const ConsentInfoSchema = z.union([
  z.object({
    tag: z.literal("skip"),
    redirectTo: z.string(),
  }),
  z.object({
    tag: z.literal("full"),
    client: ClientInfoSchema,
    scopes: z.array(z.string()),
  }),
]);

export const getConsentInfo = (url: string, consentChallenge: string) =>
  apiCall({
    url: url + PATH.getConsentInfo,
    authorizationToken: consentChallenge,
    responseSchema: ConsentInfoSchema,
  });

const AcceptConsentSchema = z.object({
  redirectTo: z.string(),
});

export const acceptConsent = (url: string, consentChallenge: string) =>
  apiCall({
    url: url + PATH.acceptConsent,
    authorizationToken: consentChallenge,
    method: "POST",
    responseSchema: AcceptConsentSchema,
  });

const RejectConsentSchema = z.object({
  redirectTo: z.string(),
});

export const rejectConsent = (url: string, loginChallenge: string) =>
  apiCall({
    url: url + PATH.rejectConsent,
    authorizationToken: loginChallenge,
    method: "POST",
    responseSchema: RejectConsentSchema,
  });
