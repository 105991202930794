import { apiCall } from "./apiCall";
import { z } from "zod";

const PATH = {
  getLoginInfo: "/login",
  acceptLogin: "/login/accept",
  rejectLogin: "/login/reject",
};

const ClientInfoSchema = z.object({
  clientId: z.string(),
  clientName: z.string(),
  owner: z.string(),
  policyUri: z.string(),
  tosUri: z.string(),
  clientUri: z.string(),
  logoUri: z.string(),
  contacts: z.array(z.string()),
});

const LoginInfoSchema = z.union([
  z.object({
    tag: z.literal("skip"),
    redirectTo: z.string(),
  }),
  z.object({
    tag: z.literal("full"),
    client: ClientInfoSchema,
    paymentRequired: z.boolean(),
  }),
]);

export const getLoginInfo = (url: string, loginChallenge: string) =>
  apiCall({
    url: url + PATH.getLoginInfo,
    authorizationToken: loginChallenge,
    responseSchema: LoginInfoSchema,
  });

const AcceptLoginSchema = z.object({
  redirectTo: z.string(),
  scanResultBlob: z.string(),
});

export const acceptLogin = (
  url: string,
  loginChallenge: string,
  livenessData: unknown,
) =>
  apiCall({
    url: url + PATH.acceptLogin,
    authorizationToken: loginChallenge,
    method: "POST",
    requestBody: livenessData,
    responseSchema: AcceptLoginSchema,
  });

const RejectLoginSchema = z.object({
  redirectTo: z.string(),
});

export const rejectLogin = (url: string, loginChallenge: string) =>
  apiCall({
    url: url + PATH.rejectLogin,
    authorizationToken: loginChallenge,
    method: "POST",
    responseSchema: RejectLoginSchema,
  });
