import { styled } from "@mui/system";
import { PropsWithChildren } from "react";
import logo from "../assets/logo.png";

const Logo: React.FC<
  React.DetailedHTMLProps<
    React.ImgHTMLAttributes<HTMLImageElement>,
    HTMLImageElement
  >
> = (props) => <img src={logo} alt="logo" {...props} />;

const LayoutLogo = styled(Logo)({
  display: "block",
  height: "50vh",
  objectFit: "contain",
});

const LayoutContainer = styled("div")(({ theme }) => ({
  display: "flex",
  flexShrink: 0,
  alignSelf: "stretch",
  alignItems: "center",
  justifyContent: "center",
  flexDirection: "column",
  padding: theme.spacing(2, 2),
}));

type Props = PropsWithChildren<{
  logo?: true;
}>;

const Layout: React.FC<Props> = (props) => {
  const { children, logo: showLogo } = props;

  return (
    <LayoutContainer>
      {showLogo && <LayoutLogo />}
      {children}
    </LayoutContainer>
  );
};

export default Layout;
