import { DelegatedFlatStateVariant, StateVariant } from "@tagged-state/core";
import { createContext, createTaggedStateHook } from "@tagged-state/react";

export type LoginInfo =
  | { tag: "skip"; redirectTo: string }
  | {
      tag: "full";
      paymentRequired: boolean;
    };

export type State =
  | StateVariant<"uninit">
  | StateVariant<"pending">
  | DelegatedFlatStateVariant<"skip", LoginInfo>
  | DelegatedFlatStateVariant<"full", LoginInfo>
  | StateVariant<"error", { error: Error }>;

const { Context, ComponentGuard } = createContext<State>({
  tag: "uninit",
  data: {},
});

export const GetLoginInfoContext = Context;
export const GetLoginInfoGuard = ComponentGuard;

export const useLoginInfoFull = createTaggedStateHook(Context, "full" as const);
