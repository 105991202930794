import React, { useEffect, useMemo, useRef } from "react";
import makeProcessor, { LivenessDataHandler } from "./makeProcessor";
import useStartCapture from "./useStartCapture";

export type CaptureState =
  | { tag: "capturing" }
  | { tag: "done" }
  | { tag: "error"; data: { error: Error } };

type FaceTecCaptureProps = {
  sessionToken: string;
  handleLivenessData: LivenessDataHandler;
  updateCaptureState: (state: React.SetStateAction<CaptureState>) => void;
};

const FaceTecCapture: React.FC<FaceTecCaptureProps> = (props) => {
  const { sessionToken, handleLivenessData, updateCaptureState } = props;

  const processor = useMemo(() => {
    const onDone = () => {
      updateCaptureState((prevState) => {
        if (prevState.tag === "error") return prevState;

        return {
          tag: "done",
        };
      });
    };
    const onError = (error: Error) => {
      updateCaptureState({
        tag: "error",
        data: { error },
      });
    };

    return makeProcessor({ onDone, handleLivenessData, onError });
  }, [handleLivenessData, updateCaptureState]);

  const [startCapture, facetecCaptureInProgress] = useStartCapture(
    processor,
    sessionToken,
  );

  const autostartTriggeredRef = useRef(false);
  useEffect(() => {
    if (!autostartTriggeredRef.current && !facetecCaptureInProgress) {
      autostartTriggeredRef.current = true;
      startCapture();
    }
  }, [facetecCaptureInProgress, startCapture]);

  return null;
};

export default FaceTecCapture;
