import { PropsWithChildren, useEffect, useState } from "react";
import buildDelay from "../buildDelay";
import { PaymentPollingContext, State } from "./state";

type PaymentPollingInitProps = {
  pollInterval: number;
  poll: () => Promise<boolean>;
};

const PaymentPollingInit: React.FC<
  PropsWithChildren<PaymentPollingInitProps>
> = (props) => {
  const { children, pollInterval, poll } = props;
  const [value, setValue] = useState<State>({ tag: "uninit", data: {} });

  useEffect(() => {
    let handleCancelDelay: null | (() => void) = null;

    const pollUnpayed = async () => {
      setValue({ tag: "unpayed", data: {} });
      while (true) {
        const [delay, cancelDelay] = buildDelay(pollInterval);
        handleCancelDelay = cancelDelay;
        let pollResult;
        try {
          pollResult = await poll();
        } catch (error) {
          setValue({
            tag: "pollError",
            data: {
              error: error as Error,
              resumePolling: () => pollUnpayed(),
            },
          });
          return;
        }

        if (pollResult) {
          break;
        }
        await delay;
      }

      setValue({ tag: "payed", data: {} });
    };

    const load = async () => {
      setValue({ tag: "initialLoading", data: {} });

      let initialLoadingResult: boolean;
      try {
        initialLoadingResult = await poll();
      } catch (error: unknown) {
        setValue({
          tag: "initialLoadingError",
          data: { error: error as Error, retryInitialLoading: load },
        });
        return;
      }

      if (initialLoadingResult) {
        setValue({ tag: "payed", data: {} });
        return;
      }

      await pollUnpayed();
    };
    load();

    return () => {
      handleCancelDelay?.();
    };
  }, [pollInterval, poll]);

  return <PaymentPollingContext.Provider value={value} children={children} />;
};

export default PaymentPollingInit;
