import { useSearchParams } from "react-router-dom";

export type ChallengeType = "consent_challenge" | "login_challenge";

const useChallengeParam = (challengeType: ChallengeType): string => {
  const [searchParams] = useSearchParams();
  const challenge = searchParams.get(challengeType);

  if (!challenge) {
    throw Error("No challenge provided");
  }

  return challenge;
};

export default useChallengeParam;
