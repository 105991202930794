import { z } from "zod";

function ErrorDataSchema<
  KindSchema extends string,
  PayloadSchema extends z.AnyZodObject,
>(kind: KindSchema, payload: PayloadSchema) {
  return z.object({
    kind: z.literal(kind),
    payload,
  });
}

export const AnyErrorDataSchema = z.object({
  kind: z.string(),
  payload: z.unknown(),
});

export type AnyErrorData = z.infer<typeof AnyErrorDataSchema>;

export const ScanResultBlobDataSchema = ErrorDataSchema(
  "ScanResultBlob",
  z.object({
    scanResultBlob: z.string(),
  }),
);
export type ScanResultBlobData = z.infer<typeof ScanResultBlobDataSchema>;

const ErrorBodySchema = <ErrorDataSchema extends typeof AnyErrorDataSchema>(
  errorData: ErrorDataSchema,
) => z.object({ data: errorData });

export class ServerError<
  ErrorData extends null | AnyErrorData = null | AnyErrorData,
> extends Error {
  static async fromResponse(
    response: Response,
  ): Promise<ServerError<null | AnyErrorData>> {
    let body = null;
    try {
      body = (await response.json()) as unknown;
      console.log(`ServerError body: ${JSON.stringify(body)}`);
    } catch (error) {
      console.log(`ServerError body was not in JSON format`);
    }

    let errorData: null | AnyErrorData = null;

    const parsedBody = ErrorBodySchema(AnyErrorDataSchema).safeParse(body);
    if (parsedBody.success) {
      errorData = parsedBody.data.data;
    }

    return new ServerError(response.status, errorData);
  }

  static is<ErrorDataSchema extends z.ZodTypeAny>(
    error: unknown,
    errorDataSchema: ErrorDataSchema,
  ): error is ServerError<z.infer<ErrorDataSchema>> {
    if (!(error instanceof ServerError)) {
      return false;
    }

    return errorDataSchema.safeParse(error.data).success;
  }

  constructor(
    public readonly status: number,
    public readonly data: ErrorData,
  ) {
    const message = `Server returned invalid status: ${status}`;
    super(message);
    this.name = "ServerError";
  }
}
