import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Container,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import React, { PropsWithChildren } from "react";
import Layout from "./Layout";
import theme from "../theme";

export const ErrorDetails: React.FC<Props> = (props) => {
  const { error } = props;
  return (
    <Accordion defaultExpanded={true}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography variant="button">Error details</Typography>
      </AccordionSummary>
      <AccordionDetails sx={{ display: "block" }}>
        <Typography
          textOverflow="ellipsis"
          overflow="auto"
          variant="body1"
          sx={{ fontFamily: "monospace" }}
        >
          {`${error.name}: ${error.message}`}
        </Typography>
      </AccordionDetails>
    </Accordion>
  );
};

type Props = PropsWithChildren<{
  error: Error;
}>;

const ErrorPage: React.FC<Props> = (props) => {
  const { error, children } = props;
  return (
    <Layout logo>
      <Container
        maxWidth="sm"
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          gap: theme.spacing(2),
        }}
      >
        {children || (
          <Typography variant="h5" textAlign="center">
            Oops! An error has occured
          </Typography>
        )}
        <ErrorDetails error={error} />
      </Container>
    </Layout>
  );
};

export default ErrorPage;
