import { apiCall } from "./apiCall";
import { z } from "zod";

const PATH = {
  pollOnline: "/poll",
};

const OnlinePollSchema = z.object({
  pollInterval: z.number(),
  online: z.number(),
});

export const pollOnline = (url: string, loginChallenge: string) =>
  apiCall({
    url: url + PATH.pollOnline,
    authorizationToken: loginChallenge,
    responseSchema: OnlinePollSchema,
  });
