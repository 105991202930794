const buildDelay = (ms: number): [Promise<unknown>, () => void] => {
  let localResolve: (value: unknown) => void;
  const handle = setTimeout(() => localResolve(undefined), ms);
  const promise = new Promise((resolve) => {
    localResolve = resolve;
  });
  const cancel = () => clearTimeout(handle);
  return [promise, cancel];
};

export default buildDelay;
